.date-entry-input {
    position: relative;
    width: 100%;    
}

.date-entry-table td.td-day,
.date-entry-table td.td-month {
    text-align: center;
    width: 60px !important;
}

.date-entry-table .td-year input {
    text-align: center;
    width: 70px;
}

.date-entry-table td.td-day .p-dropdown.p-dropdown-clearable .p-dropdown-label,
.date-entry-table td.td-month .p-dropdown.p-dropdown-clearable .p-dropdown-label{
    padding-right: 0;
}

.date-entry-input .ui-inputtext {
    width: 60px;
    font-size: 1.1em;
}

.date-entry-input table td {
    padding-right: 5px;
}

.date-entry-input .ui-dropdown {
    width: 80px;
}
